.dream-work {
    height: 680px;
    position: relative;
    color: $color-white;
    background: #383869 url('../images/star-sky.jpg') 50% 50% no-repeat;
    background-size: cover;

    @media (max-width: $screen-sm) {
        height: auto;
        padding: 0 0 40px;
    }

    &__logo-holder {
        margin: 43px 0 0 0;

        @media (max-width: $screen-md) {
            margin: 30px 0 20px;
            text-align: center;
        }
    }

    &__phone {
        text-align: right;
        font-size: 36px;
        margin: 50px 0 0 0;

        @media (max-width: $screen-sm) {
            text-align: center;
            margin: 20px 0 30px;
        }

        a {
            text-decoration: none;
            border-bottom: 0 none;
            color: $color-white;
        }
    }

    &__super-man {
        @include absoluteTR(174px, 57px);
        
        @media (max-width: $screen-lg) {
            transform: scale(.6) translateX(100px);
        }

        @media (max-width: $screen-md) {
            transform: scale(.4) translateX(350px);
        }

        @media (max-width: $screen-sm) {
            transform: scale(0.3) translateX(540px) translateY(-330px);
        }
    }

    &__first-line {
        font: 400 30px $font-bitter;
        padding-bottom: 39px;

        @media (max-width: $screen-sm) {
            font-size: 22px;
        }
    }

    &__second-line {
        font: 600 110px/1 $font-bitter;
        text-transform: uppercase;
        padding: 0 0 18px;
        letter-spacing: 6px;
        text-shadow: 0 10px 0 rgba($color-black, .1);

        @media (max-width: $screen-sm) {
            font-size: 60px;
        }
    }

    &__third-line {
        font: 600 120px/1 $font-bitter;
        text-transform: uppercase;
        letter-spacing: 5px;
        text-shadow: 0 10px 0 rgba($color-black, .1);

        @media (max-width: $screen-sm) {
            font-size: 66px;
        }
    }

    &__button {
        padding: 40px 0 0;

        @media (max-width: $screen-sm) {
            padding: 20px 0 0;
        }
    }

    &__arrow {
        @include sprite($arrow);
        @include absoluteTL(-105px, -150px);

        @media (max-width: $screen-sm) {
            display: none;
        }

        &:after {
            @include block(90px, 30px);
            @include absoluteTL(60px, -100px);
            content: 'Присылай свое резюме';
            text-align: left;
            font-size: 16px;
            font-style: italic;
            color: #fff;
            opacity: .5;
        }
    }

    &__send-resume {
        //font-size: 16px;
        //font-style: italic;
        color: #fff;
        opacity: .5;
        padding: 0 0 20px;
    }
}

.star-shadow {
    position: relative;

    &:after {
        @include sprite($star-shadow);
        content: '';
        display: block;
        @include absoluteTL(50%, 50%);
        margin-top: -15px;
        margin-left: -13px;

        @media (max-width: $screen-sm) {
            display: none;
        }
    }
}

.logo-white {
    @include sprite($logo-white);
    display: inline-block;
}

.super-man {
    display: block;
    @include sprite($super-man);
    animation: super-man-flying 1200ms infinite cubic-bezier(0.550, 0.085, 0.680, 0.530);

    &:before {
        display: block;
        @include sprite($air);
        content: '';
        @include absoluteTR(-15px, -15px);
        animation: super-man-air 1200ms infinite;
    }
}


// super man animation
@keyframes super-man-flying {
    0% {
        transform: translateX(0) translateY(0);
    }

    50% {
        transform: translateX(15px) translateY(-25px);
    }

    100% {
        transform: translateX(0) translateY(0);
    }
}

@keyframes super-man-air {
    0% {
        transform: translateX(0) translateY(0);
    }

    50% {
        transform: translateX(3px) translateY(-3px);
    }

    100% {
        transform: translateX(0) translateY(0);
    }
}