/* ==========================================================================
   Base styles
   ========================================================================== */
html, body {
    min-width: $page-width-min;
    font: 300 #{$base-font-size}/#{$base-line-height} $font-blogger;
    color: $base-text-color;
    background: #fff;

    @media (max-width: $screen-md) {
        font-size: $base-font-size - 3px;
    }
}

.page-wrapper {
    min-width: $page-width-min;
    background: #e5e5e5;
}

.page {
    max-width: 1375px;
    margin: 0 auto;
    background: $color-white;
}

.section {
    padding: 62px 0 0;

    @media (max-width: $screen-md) {
        padding: 40px 0 0;
    }

    &--blue {
        background: #f7f8ff;
    }
}

.section-padding {
    padding: 0 10px;
}

.header {
    color: $color-black;
    font: 600 48px/1.25 $font-bitter;
    position: relative;
    margin: -13px 0 0 0;

    @media (max-width: $screen-md) {
        margin: 0 0 60px;
        font-size: 36px;
    }

    &:after {
        @include block(170px, 10px);
        background: $color-yellow;
        content: '';
        @include absoluteBL(-54px, 0);

        @media (max-width: $screen-md) {
            height: 5px;
            bottom: -30px;
        }
    }

    &--arrow {

        &:after {
            width: 70px;
            left: 100px;

            @media (max-width: $screen-lg) {
                width: 170px;
                left: 0;
            }
        }

        &:before {
            @include sprite($arrow-2);
            content: '';
            @include absoluteBL(-63px, 0);
            display: block;

            @media (max-width: $screen-lg) {
                display: none;
            }
        }
    }

    &--no-line {

        &:after, &:before {
            display: none;
        }
    }
}

.section-header {
    font: 600 48px $font-bitter;
    line-height: $base-line-height;
    color: $color-black;
    text-align: center;

    @media (max-width: $screen-md) {
        font-size: 36px;
    }

    @media (max-width: $screen-sm) {
        font-size: 28px;
    }

    &--white {
        color: $color-white;
    }

    &--line {
        position: relative;

        &:after {
            @include block(170px, 10px);
            background: $color-yellow;
            content: '';
            margin: 35px auto 0;
        }
    }
}