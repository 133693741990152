/* ==========================================================================
   Links
   ========================================================================== */
a {
    text-decoration: none;
    color: $base-text-color;
    border-bottom: 1px solid $color-yellow;

    &:hover {
        text-decoration: none;
        border-bottom: 0 none;
    }

    &:focus,
    &:active {
        color: $color-blue;
    }

    &,
    &:hover,
    &:active,
    &:focus {
        outline: 0 none !important;
    }

    > *[class^='icon__'],
    > *[class*='icon__'] {
        display: inline-block;
        vertical-align: -1px;
        margin: 0 8px 0 0;
    }

    > span + *[class^='icon__'],
    > span + *[class*='icon__'] {
        margin: 0 0 0 8px;
    }
}