a.button,
.button {
    @include inline-block(auto, 60px);
    @include user-select(none);
    @include transition(500ms);
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    color: $color-grey-dark;
    font: 400 30px/60px $font-bitter;
    text-decoration: none;
    border-radius: 30px;
    padding-left: 30px;
    padding-right: 30px;
    border: 0 none;
    background: #fff500;
    position: relative;
    top: 0px;
    box-shadow: 0 5px 0 0 rgba($color-black, .2);
    @include transition(100ms);

    @media (max-width: $screen-sm) {
        font-size: 20px;
    }

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            @include tab-focus;
        }
    }

    &:hover,
    &:focus,
    &.focus {
        text-decoration: none;
    }

    &:hover {
        color: $color-grey-dark;
        box-shadow: 0 6px 0 0 rgba($color-black, .2);
    }

    &:active,
    &.active {
        outline: 0;
        top: 2px;
        box-shadow: 0 4px 0 0 rgba($color-black, .2);
    }

    &_full-width {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    &--big-shadow {
        box-shadow: 0 10px 0 0 rgba($color-black, .2);

        &:hover {
            box-shadow: 0 11px 0 0 rgba($color-black, .2);
        }

        &:active,
        &.active {
            box-shadow: 0 8px 0 0 rgba($color-black, .2);
        }

    }
}