//
// Typography
// --------------------------------------------------


// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;

    small,
    .small {
        font-weight: normal;
        line-height: 1;
        color: $headings-small-color;
    }
}

h1, .h1,
h2, .h2,
h3, .h3 {
    margin-top: $line-height-computed;
    margin-bottom: ($line-height-computed / 2);

    small,
    .small {
        font-size: 65%;
    }
}
h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);

    small,
    .small {
        font-size: 75%;
    }
}

h1, .h1 { font-size: $font-size-h1; font-weight: 600; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; line-height: 1.3; }
h4, .h4 { font-size: $font-size-h4; font-weight: 400; line-height: 1.3 }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }


// Body text
// -------------------------

p {

    &:not(:last-child) {
        margin: 0 0 26px;
    }

    strong {
        color: $color-black;
    }
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
    font-size: floor((100% * $font-size-small / $font-size-base));
}

mark,
.mark {
    background-color: $state-warning-bg;
    padding: .2em;
}

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }

// Transformation
.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

// Contextual colors
.text-muted {
    color: $text-muted;
}

// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
    margin-top: 0;
    margin-bottom: ($line-height-computed / 2);
    ul,
    ol {
        margin-bottom: 0;
    }
}

// List options

// [converter] extracted from `.list-unstyled` for libsass compatibility
@mixin list-unstyled {
    padding-left: 0;
    list-style: none;
}
// [converter] extracted as `@mixin list-unstyled` for libsass compatibility
.list-unstyled {
    @include list-unstyled;
}

.list {
    @include list-unstyled;

    li {
        padding: 0 0 0 20px;
        position: relative;

        &:before {
            @include circle(10px);
            @include absoluteTL(5px, 0);
            background: #fc0;
            content: '';
        }
    }

    li + li {
        margin-top: 8px;
    }
}

blockquote {
    text-align: center;
    border: 0 none;
    font: 300 22px/1.27 $font-dinpro;
    font-style: italic;
    color: $color-black;
    padding: 0 100px;

    @media (max-width: $screen-sm) {
        padding: 0 20px;
    }

    p {
        margin: 0 0 10px;
    }

    footer {
        text-align: left;
        width: 300px;
        margin: 0 auto;

        @media (max-width: $screen-sm) {
            width: 200px;
        }

        &:after,
        &:before {
            display: none;
        }
    }
}

.muted {
    color: $color-grey-light;
}