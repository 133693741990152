/* ==========================================================================
   Icons
   ========================================================================== */

.icon,
*[class^='icon__'],
*[class*='icon__'] {
    display: inline-block;
}

.icon__gift         { @include sprite($gift); }
.icon__leaders      { @include sprite($leaders); }
.icon__suitecase    { @include sprite($suitecase); }
.icon__percent      { @include sprite($percent); }
.icon__medal        { @include sprite($medal); }
.icon__gear-check   { @include sprite($gear-check); }
.icon__gear-head    { @include sprite($gear-head); }
.icon__teaching     { @include sprite($teaching); }
.icon__house        { @include sprite($house); }
.icon__desk         { @include sprite($desk); }
.icon__kitchen      { @include sprite($kitchen); }
.icon__super-heroes { @include sprite($super-heroes); }
.icon__stars-like   { @include sprite($stars-like); }
.icon__fly          { @include sprite($fly); }
.icon__muscle       { @include sprite($muscle); }
.icon__arrow-dots   { @include sprite($arrow-dots); }
.icon__docs         { @include sprite($docs); }
.icon__talking      { @include sprite($talking); }
.icon__phone        { @include sprite($phone); }