/* ==========================================================================
   Vendors
   ========================================================================== */
@import "./../../bower_components/swiper/dist/css/swiper.min";

.swiper-button-prev,
.swiper-button-next {
    background: $color-white;
    @include transition(500ms);
    @include opacity(100);
    width: 40px;
    height: 90px;
    margin-top: -45px;

    @media (max-width: $screen-md) {
        height: 50px;
        margin-top: -25px;
    }

    &:hover {
        @include opacity(70);
    }

    &:after {
        content: '';
        display: inline-block;
        margin-top: 38px;

        @media (max-width: $screen-md) {
            height: 50px;
            margin-top: 20px;
        }
    }
}

.swiper-button-prev {
    left: 0;
    border-radius: 0 5px 5px 0;

    &:after {
        @include sprite($swiper-left);
        margin-left: 8px;
    }
}

.swiper-button-next {
    right: 0;
    border-radius: 5px 0 0 5px;
    text-align: right;

    &:after {
        @include sprite($swiper-right);
        margin-right: 8px;
    }
}

@import  "./../../bower_components/magnific-popup/dist/magnific-popup";

.mfp-bg {
    background-color: #22242d;
    @include opacity(80);
}

@import  "./../../bower_components/animate.css/animate.min";