form {

}

.form-group {

}

.form-control {

    &__valid {

    }
}

.control-label {
    color: #333;
}

.form-horizontal {

    .control-label {
        text-align: left;
    }
}

input:not([type="checkbox"]):not([type="radio"]),
input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea,
.select2-container .select2-selection--single {

    &, &.form-control {
        @include inline-block(100%, 36px);
        border-radius: 0;
        padding: 0 0 0 8px;
        line-height: 34px;
        box-shadow: inset 0 2px 2px 0 rgba($color-black, .1);
        border: 1px solid #b1c6dc;
    }
}

textarea {

    &, &.form-control {
        height: 55px;
        resize: vertical;
        line-height: 1.3;
        padding-top: 5px;
        padding-bottom: 5px;

        &.big {
            height: 170px;
        }
    }
}

.checkbox-input {
    display: block;
    padding: 7px 0 2px;

    label {
        font-weight: 300;
    }

    input[type="checkbox"] {
        margin: 0 7px 0 0;
        display: inline-block;
    }
}

.input-group {
    position: relative;

    .form-control {

    }

    .input-group-addon {
        @include absoluteTR(1px, -40px);
        width: 40px !important;
        bottom: 1px;
        text-align: center;
        vertical-align: middle;
        line-height: 38px;

        @media (max-width: $screen-sm) {
            right: 1px;
        }

        &.inside {
            right: 1px;
        }
    }
}

.upload-circle {
    @include block(100%, 0);
    padding-bottom: 100%;
    position: relative;

    @media (max-width: $screen-lg) {
        padding-bottom: 0;
        max-width: 150px;
        height: 150px;
        margin-bottom: $form-group-margin-bottom;
    }

    &__link {
        @include absoluteTL(0, 0);
        @include block(100%, 100%);
        @include transition(500ms);
        border-radius: 100%;
        border: 2px dashed $color-grey;
        text-decoration: none;
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: stretch;
        align-content: stretch;

        &:hover {
            opacity: 80;

            span {
                border-bottom: 0 none;
            }
        }

        span {
            color: $color-grey-dark;
            text-decoration: none;
            border-bottom: 1px dotted $color-grey;
        }
    }


}

// select2 customization
.select2-container {
    height: 36px;

    .select2-selection {

        .select2-selection__rendered {
            line-height: 34px;
            padding-left: 0;
        }

        .select2-selection__arrow {
            height: 36px;
            width: 25px;

            b {
                border-color: $color-black transparent transparent transparent;
                border-width: 3px 3px 0 3px;
                margin-left: -3px;
                margin-top: -2px;
            }
        }
    }
}

/* ==========================================================================
   Checkbox customization
   ========================================================================== */
[type="checkbox"]{

    &:not(:checked),
    &:checked {
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            padding-left: 24px;
            cursor: pointer;

            &:before {
                @include block(17px, 17px);
                @include absoluteTL(2px, 0);
                content: '';
                border: 1px solid #b1c6dc;
                background: #fff;
                border-radius: 1px;
                box-shadow: inset 0 1px 1px rgba($color-black, .1)
            }

            &:after {
                @include absoluteTL(5px, 3px);
                content: '✔';
                font-size: 14px;
                font-weight: 300;
                line-height: 0.8;
                color: $color-black;
                transition: all .2s;
            }
        }

        &:focus + label:before {
            outline: 0 none !important;
        }
    }

    &:checked {

        & + label {

            &:before {
                //border-color: $color-pink;
            }

            &:after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    &:not(:checked) {

        & + label {

            &:before {

            }

            &:after {
                opacity: 0;
                transform: scale(0);
            }
        }
    }

    &:disabled {

        &:not(:checked),
        &:checked {

            & + label {
                //color: #aaa;

                &:before {
                    //box-shadow: none;
                    //border-color: #bbb;
                    //background-color: #ddd;
                }

                &:after {
                    //color: #999;
                }
            }
        }
    }
}
